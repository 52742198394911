<template>
  <div>
    <!--Map Section-->
    <section class="map-section">
      <div class="map-outer">
        <!--Map Canvas-->
        <div
          class="map-canvas"
          data-zoom="14"
          data-lat="-41.1350241"
          data-lng="-71.31007"
          data-type="roadmap"
          data-hue="#ffc400"
          data-title="Remises Centro"
          data-content="<h6>Elflein 7,</h6><br> San Carlos de Bariloche<br><a href='mailto:info@remisescentro.com.ar'>info@remisescentro.com.ar</a>"
          style="height: 480px"
        ></div>
      </div>
    </section>

    <!--Contact Section-->
    <section class="contact-section">
      <div class="auto-container">
        <div class="contact-info">
          <div class="sec-title">
            <h2>Formulario de contacto</h2>
            <div class="desc-text">
              Si tenes alguna duda, podes enviarnos un mensaje a nuestro correo
              electronico para que te respondamos cuanto antes.
            </div>
          </div>

          <div class="row clearfix">
            <div class="info-column col-md-8 col-sm-6 col-xs-12">
              <!--Form Container -->
              <div class="form-container">
                <div class="sec-title">
                  <h3>Rellene el siguiente formulario</h3>
                </div>

                <!--Contact Form-->
                <div class="contact-form default-form">
                  <form method="post" action="" id="contact-form">
                    <div class="row clearfix">
                      <div class="form-group col-md-6 col-sm-6 col-xs-12">
                        <div class="field-label">
                          Nombre <span class="req">*</span>
                        </div>
                        <input
                          type="text"
                          name="firstname"
                          value=""
                          placeholder="Ingrese su nombre"
                        />
                      </div>

                      <div class="form-group col-md-6 col-sm-6 col-xs-12">
                        <div class="field-label">
                          Apellido <span class="req">*</span>
                        </div>
                        <input
                          type="text"
                          name="lastname"
                          value=""
                          placeholder="Ingrese su apellido"
                        />
                      </div>

                      <div class="form-group col-md-6 col-sm-6 col-xs-12">
                        <div class="field-label">
                          Email <span class="req">*</span>
                        </div>
                        <input
                          type="email"
                          name="email"
                          value=""
                          placeholder="su@email.com"
                        />
                      </div>

                      <div class="form-group col-md-6 col-sm-6 col-xs-12">
                        <div class="field-label">
                          Telefono <span class="req">*</span>
                        </div>
                        <input
                          type="text"
                          name="phone"
                          value=""
                          placeholder="+54 294 4644433"
                        />
                      </div>

                      <div class="form-group col-md-12 col-sm-12 col-xs-12">
                        <div class="field-label">
                          Mensaje <span class="req">*</span>
                        </div>
                        <textarea
                          name="message"
                          placeholder="Escriba su mensaje"
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12 col-sm-12 col-xs-12">
                        <div
                          id="recaptcha"
                          class="g-recaptcha"
                          data-sitekey="6LdMpMsbAAAAAHEXeqk5JKIDtnebjcy-KVtyLEKK"
                          data-callback="onSubmit"
                        ></div>
                        <h6 id="sent-message" class="alert alert-success" style="display: none">
                          <i class="fa fa-check"></i> El mensaje se envio correctamente, en breve le responderemos. Gracias!
                        </h6>
                        <h6 id="error-message" class="alert alert-danger" style="display: none">
                          <i class="fa fa-close"></i> Hubo un error al enviar el mensaje, intentelo mas tarde. Disculpe las molestias.
                        </h6>
                        <button
                          type="submit"
                          class="g-recaptcha theme-btn btn-style-four"
                        >
                          ENVIAR
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--Info Column-->
            <div class="info-column col-md-4 col-sm-6 col-xs-12">
              <h3>Datos de Contacto</h3>
              <ul>
                <li>
                  <span class="icon flaticon-construction"></span> Elflein 7,
                  San Carlos de Bariloche, Rio Negro.
                </li>
                <li>
                  <span class="icon flaticon-technology-5"></span>
                  {{ $parent.phones[0].phone }}
                </li>
                <li>
                  <span class="icon flaticon-envelope"></span>
                  info@remisescentro.com.ar
                </li>
                <li>
                  <span class="icon flaticon-earth-1"></span>
                  www.remisescentro.com.ar
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "Contact",
  methods: {
    GmapInit() {
      console.log("GmapInit");
      const Gmap = $(".map-canvas");
      Gmap.each(function () {
        var $this = $(this),
          lat = "",
          lng = "",
          zoom = 12,
          scrollwheel = false,
          zoomcontrol = true,
          draggable = true,
          mapType = google.maps.MapTypeId.ROADMAP,
          mapTypeControl = false,
          title = "",
          contentString = "",
          dataLat = $this.data("lat"),
          dataLng = $this.data("lng"),
          dataZoom = $this.data("zoom"),
          dataType = $this.data("type"),
          dataScrollwheel = $this.data("scrollwheel"),
          dataZoomcontrol = $this.data("zoomcontrol"),
          dataHue = $this.data("hue"),
          dataTitle = $this.data("title"),
          dataContent = $this.data("content");

        if (dataZoom !== undefined && dataZoom !== false) {
          zoom = parseFloat(dataZoom);
        }
        if (dataLat !== undefined && dataLat !== false) {
          lat = parseFloat(dataLat);
        }
        if (dataLng !== undefined && dataLng !== false) {
          lng = parseFloat(dataLng);
        }
        if (dataScrollwheel !== undefined && dataScrollwheel !== null) {
          scrollwheel = dataScrollwheel;
        }
        if (dataZoomcontrol !== undefined && dataZoomcontrol !== null) {
          zoomcontrol = dataZoomcontrol;
        }
        if (dataType !== undefined && dataType !== false) {
          if (dataType == "satellite") {
            mapType = google.maps.MapTypeId.SATELLITE;
          } else if (dataType == "hybrid") {
            mapType = google.maps.MapTypeId.HYBRID;
          } else if (dataType == "terrain") {
            mapType = google.maps.MapTypeId.TERRAIN;
          }
        }
        if (dataTitle !== undefined && dataTitle !== false) {
          title = dataTitle;
        }
        if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
          draggable = false;
        }

        var mapOptions = {
          zoom: zoom,
          scrollwheel: scrollwheel,
          zoomControl: zoomcontrol,
          draggable: draggable,
          center: new google.maps.LatLng(lat, lng),
          mapTypeId: mapType,
          mapTypeControl: mapTypeControl,
        };
        var map = new google.maps.Map($this[0], mapOptions);

        var image = require("../assets/images/logo-map.png");
        if (dataContent !== undefined && dataContent !== false) {
          contentString =
            '<div class="map-data">' +
            '<figure> <img src="' +
            require("../assets/images/logo.png") +
            '" alt="Remises Centro Mapa"></figure>' +
            "<h6>" +
            title +
            "</h6>" +
            '<div class="map-content">' +
            "<strong>" +
            dataContent +
            "</strong>" +
            "</div>" +
            "</div>";
        }
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
        });

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: map,
          title: title,
        });
        if (dataContent !== undefined && dataContent !== false) {
          google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
          });
          infowindow.open(map, marker);
        }

        if (dataHue !== undefined && dataHue !== false) {
          var styles = [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  color: "#ececec",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 45,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  color: "#999999",
                },
                {
                  visibility: "on",
                },
              ],
            },
          ];
          map.setOptions({ styles: styles });
        }
      });
    },
  },
  mounted() {
    const self = this;
    $(document).ready(function () {
      self.GmapInit();
      jQuery("#contact-form").validate({
        rules: {
          firstname: { required: true },
          lastname: { required: true },
          email: { required: true },
          phone: { required: true },
          message: { required: true },
        },
        submitHandler: function (form, event) {
          event.preventDefault();
          grecaptcha.ready(function () {
            grecaptcha
              .execute("6LdMpMsbAAAAAHEXeqk5JKIDtnebjcy-KVtyLEKK", {
                action: "submit",
              })
              .then(function (token) {
                $(".g-recaptcha.theme-btn").attr("disabled", true);
                $.ajax({
                  type: "POST",
                  url: process.env.BASE_URL + "forms/contact.php",
                  data: $(form).serialize() + "&token=" + token,
                  timeout: 40000,
                })
                  .done(function (msg) {
                    console.log(msg, msg.trim());
                    if (msg.trim() == "OK") {
                      $(".g-recaptcha.theme-btn").attr("disabled", false);
                      $("#sent-message").slideDown();
                      $(form)
                        .find("input:not(input[type=submit]), textarea")
                        .val("");
                    } else {
                      $("#sent-message").slideDown();
                    }
                  })
                  .fail(function (data) {
                    console.log(data);
                    // var error_msg = "Form submission failed!<br>";
                    // if (data.statusText || data.status) {
                    //   error_msg += "Status:";
                    //   if (data.statusText) {
                    //     error_msg += " " + data.statusText;
                    //   }
                    //   if (data.status) {
                    //     error_msg += " " + data.status;
                    //   }
                    //   error_msg += "<br>";
                    // }
                    // if (data.responseText) {
                    //   error_msg += data.responseText;
                    // }
                    // this_form.find(".loading").slideUp();
                    // this_form
                    //   .find(".error-message")
                    //   .slideDown()
                    //   .html(error_msg);
                  });
              });
          });
        },
      });
    });

  },
};
</script>

<style>
</style>